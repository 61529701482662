
const API_BASE_PATH = process.env.VUE_APP_API_BASE_PATH;


function post(path, data, {suppressUnauthenticatedMessage} = {}) {
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'POST',
    headers: {'content-type': 'application/json'},
    body: JSON.stringify(data),
    credentials: "include",
  })
  .then((r) => toJson(r, {suppressUnauthenticatedMessage})).catch(globalErrorHandler);
}

function postFormData(path, formData) {
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'POST',
    //headers: {'content-type': 'application/json'},
    body: formData,
    credentials: "include",
  })
  .then((r) => toJson(r, {})).catch(globalErrorHandler);
}

function put(path, data) {
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'PUT',
    headers: {'content-type': 'application/json'},
    body: JSON.stringify(data),
    credentials: "include",
  })
  .then((r) => toJson(r, {})).catch(globalErrorHandler);
}

function get(path, params = {}, {suppressUnauthenticatedMessage} = {}) {
  var querystring = encodeQueryData(params);
  path = path + '?' + querystring;
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'GET',
    headers: {'content-type': 'application/json'},
    credentials: "include",
  })
  .then((r) => toJson(r, {suppressUnauthenticatedMessage})).catch(globalErrorHandler);
}

function head(path, params = {}, {suppressUnauthenticatedMessage} = {}) {
  /*
  This uses a HEAD call and returns the promise for it. You can evaluate response.ok in promise handlers

  Ex:
    ```
    api.head('https://example.com/myfile.pdf')
      .then((r) => {
        this.showTheMyFileButton = r.ok
      })
      .catch((reason) => {
        console.warn(`Problem retrieving existence of myfile.pdf: ${reason}.`)
      })
     ```
   */
  var querystring = encodeQueryData(params);
  path = path + '?' + querystring;
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'head',
    credentials: "include",
  })
}

function remove(path) {
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'DELETE',
    headers: {'content-type': 'application/json'},
    credentials: "include",
  })
  .then((r) => toJson(r, {})).catch(globalErrorHandler);
}

function download(path, params = {}, fileName) {
  var querystring = encodeQueryData(params);
  path = path + '?' + querystring;
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'GET',
    credentials: "include",
  })
  .then(async (response) => {
    try {
      // attempt to get filename from the headers, if possible
      fileName = fileName || response.headers.get('Content-Disposition').split('filename=')[1];
    } catch (e) {
      console.warning("Exception getting filename from headers; continuing", e);
    }

    // const newBlob = new Blob([await response.blob()], {type: 'application/pdf'});
    const newBlob = new Blob([await response.blob()]);

    downloadBlob(newBlob, fileName);
  });
}

/**
 * Downloads a file from the server and returns the retrieved binary data as a Blob
 * @param path the API path that will return the binary data
 * @param params query string params as key/value pairs. defaults to {}
 * @returns {Promise<Blob>}
 */
function getDownloadBlob(path, params = {}) {
  const querystring = encodeQueryData(params);
  path = path + '?' + querystring;
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'GET',
    credentials: "include",
  })
    .then(async (response) => {
      return new Blob([await response.blob()]);
    });
}

/**
 * Downloads the response from the given URL and returns the retrieved binary data as a Blob.
 * Use this method to retrieve a "file" from any URL, not just an API path. If the response is not
 * "ok" (e.g. 404), this method will return `null`..
 * @param {string} sourceUrl the URL to retrieve the binary data from. It should return the binary data in the body, accessible from response.blob()
 * @param {{string:string}} params query string params as key/value pairs. defaults to {}, optional
 * @param {string} mimeType. Defaults to 'application/octet-stream'
 * @returns {Promise<Blob> | null}
 */
function getRemoteDownloadBlob(sourceUrl, params = {}, mimeType='application/octet-stream') {
  if (params && Object.keys(params).length > 0) {
    const querystring = encodeQueryData(params);
    sourceUrl = sourceUrl + '?' + querystring;
  }
  return fetch(sourceUrl, {
    method: 'GET',
  })
    .then(async (response) => {
      if (response.ok) {
        return new Blob([await response.blob()], {type: response.headers.get('Content-Type') || mimeType});
      } else {
        console.error(`Error retrieving remote file from ${sourceUrl}: ${response.status} ${response.statusText}`);
        return null;
      }
    });
}



function postDownload(path, data = {}, fileName) {
  return fetch(`${API_BASE_PATH}${path}`, {
    method: 'POST',
    headers: {'content-type': 'application/json'},
    body: JSON.stringify(data),
    credentials: "include",
  })
  .then((response) => {
    try {
      fileName = fileName || response.headers.get('Content-Disposition').split('filename=')[1];
    } catch (e) {
      console.error("Exception getting filename from headers", e);
    }
    downloadBlob(response.blob(), fileName);
  });
}

function downloadBlob(blob, fileName) {
  if (isIE11()) {
    downloadBlobIE11(fileName, blob);
  } else {
    downloadBlobModernBrowser(fileName, blob)
  }
}

function isIE11() {
  return navigator.msSaveOrOpenBlob;
}

function downloadBlobIE11(fileName, blob) {
  // https://stackoverflow.com/questions/46232980/click-giving-access-denied-in-ie11/46233123
  navigator.msSaveOrOpenBlob(blob, fileName);
}

function downloadBlobModernBrowser(fileName, blob) {
  // create url object

  let objectUrl = window.URL.createObjectURL(blob);
  let a = document.createElement("a");
  document.body.appendChild(a);
  a.href = objectUrl;
  a.download = fileName;
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(a);
  }, 250);
}


function toJson(response, {suppressUnauthenticatedMessage}) {
  if (response.status >= 500) {
    throw "The server encountered an error processing this request";
  } else if (response.status === 401 && !suppressUnauthenticatedMessage) {
    if (confirm("Your session has expired. Press OK to continue to the sign in page to continue.")) {
      // go to /?redirectTo={urlencoded current url path & all trailing | query}
      window.location.href = `/?redirectTo=${encodeURIComponent(window.location.pathname + window.location.search)}`;
    } else {
      throw "Session expired";
    }
  }

  return response.json()
}

function globalErrorHandler(reason) {
  console.error("There was an error communicating with the server.", reason);
  //alert(`There was an error communicating with the server.`);
  // Allow higher level code to deal with communications errors if desired.
  throw new Error(reason)
}

export default {
  download: (path, params = {}, fileName) => download(path, params, fileName),
  getDownloadBlob: getDownloadBlob,
  getRemoteDownloadBlob: getRemoteDownloadBlob,

  // Site config
  getSiteConfig() {
    return get('/api/site-config/');
  },
  // Users endpoints
  registerUser(data) {
    return post("/api/users/register", data);
  },
  confirmUserEmailFinalize(data) {
    return post("/api/users/confirm-email-finalize", data);
  },
  login(data) {
    return post("/api/users/login", data, {suppressUnauthenticatedMessage: true});
  },
  logout() {
    return get("/api/users/logout");
  },
  me() {
    return get("/api/users/me", {}, {suppressUnauthenticatedMessage: true});
  },
  updatePassword(data) {
    return post("/api/users/password", data);
  },
  forgotPassword(data) {
    return post("/api/users/reset-password", data);
  },
  forgotPasswordFinalize(data) {
    return post("/api/users/reset-password-finalize", data);
  },
  invitationFinalize(data) {
    return post("/api/users/invitation-finalize", data);
  },
  searchUsers(params) {
    return get("/api/users", params);
  },
  getUserByUserId(userId) {
    return get("/api/users/"+userId);
  },
  // Get all the assigned cases to specific user
  assignedCases(userId, filters) {
    return post(`/api/cases/${userId}/usercase`, filters);
  },
  // Delete specific assigned case
  deleteAssignedCase(userId, caseId) {
    return remove(`/api/cases/${userId}/${caseId}/usercasedelete`);
  },
  createUser(data) {
    return post("/api/users", data);
  },
  updateUser(data) {
    return put("/api/users/"+data.id, data);
  },
  updateUserPassword(userId, password, passwordConfirmed) {
    return put("/api/users/"+userId+"/password", {
      "password": password,
      "passwordConfirmed": passwordConfirmed,
    });
  },
  updateMe(data) {
    return put("/api/users/me", data);
  },
  deleteUser(id) {
    return remove("/api/users/"+id);
  },
  addRolesToUser(userId, data) {
    return post("/api/users/"+userId+"/roles", data);
  },
  addTagsToUser(userId, data) {
    return post("/api/users/"+userId+"/tags", data);
  },
  sendInvitation(data) {
    return post("/api/users/invitation", data);
  },

  // Enrollments endpoints
  postNewEnrollmentSession(params) {
    return post('/api/wizard/session', params);
  },
  postSelfEnrollmentSession(params) {
    return post('/pages/enroll/self-enrollment-begin', params);
  },
  prepAgentEnrollRemoteSigSession(caseSlug, tokenUUID) {
    return get(`/pages/agent-enroll-remote-sig/${caseSlug}/${tokenUUID}`, {});
  },
  prepCallCenterReviewSession(caseSlug, tokenUUID) {
    return get(`/pages/review/${caseSlug}/${tokenUUID}`, {});
  },

  searchEnrollments(params) {
    return get("/api/enrollments", params);
  },
  searchPausedEnrollmentForApplicant(case_id, applicant_id) {
    const data = {case_id: case_id, applicant_id: applicant_id};
    return post('/api/enrollments/applicant-paused-search', data);
  },
  startGeneratingEnrollmentCsv(params) {
    // we assume that the params set the results format to CSV
    return get("/api/enrollments", params);
  },
  getEnrollmentDetails(enrollmentId) {
    return get("/api/enrollments/"+enrollmentId, {extended:1});
  },
  getEnrollmentRecordings(enrollmentId) {
    return get("/api/enrollments/"+enrollmentId+"/recordings");
  },
  deleteEnrollment(enrollmentId) {
    return remove("/api/enrollments/"+enrollmentId);
  },

  getApplicantDetails(applicantId) {
    return get('/api/applicants/'+applicantId);
  },

  searchCensus(case_id, ssn) {
    return get(`/api/cases/${case_id}/census/${ssn}`);
  },

  deleteApplicant(applicantId) {
    return remove(`/api/applicants/${applicantId}`);
  },

  searchTypes() {
    return get("/api/enrollments/search-types");
  },
  searchEnrollmentReports(data) {
    return post("/api/enrollments/report-search", data);
  },
  downloadEnrollmentReportsCsv(data, fileName) {
    console.warning('downloadEnrollmentReportsCsv is deprecated');
    return this.startGeneratingEnrollmentReportsCsv(data);
  },
  startGeneratingEnrollmentReportsCsv(data) {
    return post("/api/enrollments/report-search", data);
  },

  // EnrollmentSessions endpoints
  sendRemoteSignatureEmail(enrollmentSessionId, email) {
    let data = {
      email
    };
    return post("/api/enrollmentsessions/" + enrollmentSessionId + "/remote-signature-email", data)
  },
  getAgentUnsignedSessions(params) {
    return get("/api/enrollmentsessions/agent-unsigned", params)
  },
  getEnrollmentSessionDetails(enrollmentSessionId) {
    return get("/api/enrollmentsessions/" + enrollmentSessionId + "/details")
  },
  agentSignSessionEnrollments(enrollmentSessionId, formData) {
    return post("/api/enrollmentsessions/" + enrollmentSessionId + "/agent-signature", formData)
  },

  // Reports endpoints
  searchReports() {
    return get("/api/reports");
  },
  createReport(data) {
    return post("/api/reports", data);
  },
  updateReport(data) {
    return put("/api/reports/"+data.id, data);
  },
  deleteReport(reportId) {
    return remove("/api/reports/"+reportId);
  },
  searchFilters() {
    return get("/api/reports/filters");
  },

  // Case endpoints
  searchCases() {
    return get("/api/cases");
  },
  searchCasesWithFilters(caseFilters) {
    return post("/api/cases/search", caseFilters);
  },
  startExportingCases(caseFilters, fileName) {
    return post("/api/cases/export", caseFilters);
  },
  getCase(caseId) {
    return get("/api/cases/"+caseId);
  },
  getCaseDetails(caseId) {
    // details API returns more details stats for case
    return get(`/api/cases/details/${caseId}`);
  },
  getCaseDivisionsForCaseId(caseId) {
    /**
     * This will return an array of JSON representations of CaseDivision entities
     *
     * ```
     * {
     *     'id': self.id,
     *     'case_id': self.case_id,
     *     'division_name': self.division_name,
     *     'group_and_division_name': self.group_and_division_name,
     *     'default_city': self.default_city,
     *     'default_state': self.default_state,
     *     'created_at': self.created_at,
     *     'updated_at': self.updated_at,
     * }
     * ```
     */
    return get(`/api/cases/${caseId}/divisions`);
  },

  createCase(data) {
    return post("/api/cases", data);
  },
  agentSplitError(data) {
    return post("/api/wizard/percentage-calculation", data);
  },
  updateCase(data) {
    return put("/api/cases/"+data.id, data);
  },
  deleteCase(id) {
    return remove("/api/cases/"+id);
  },
  reactivateCase(id) {
    return post(`/api/cases/${id}/reactivate`);
  },
  addProductsToCase(caseId, data) {
    return post("/api/cases/"+caseId+"/products", data);
  },
  getProductsForCase(caseId) {
    return get("/api/cases/"+caseId+"/products");
  },
  validateExistingCaseSettings(caseId) {
    return get(`/api/cases/${caseId}/validate`);
  },
  validateCaseSettings(caseId, data) {
    return post(`/api/cases/${caseId}/validate`, data);
  },
  validateProductsForCase(caseId, data) {
    return post(`/api/cases/${caseId}/validate-products`, data);
  },
  addUsersToCase(caseId, data) {
    return post("/api/cases/"+caseId+"/agents", data);
  },
  getUsersForCase(caseId) {
    return get("/api/cases/"+caseId+"/agents");
  },
  getAgentsWithAccess(caseId) {
    return get(`/api/cases/${caseId}/agents_with_access`)
  },
  getSelfEnrollUrlForCase(caseId) {
    return get("/api/cases/"+caseId+"/self-enroll-url");
  },
  getAgentEnrollUrlForCase(caseId) {
    return get("/api/cases/"+caseId+"/agent-enroll-remote-sig-url");
  },
  getTagsByCaseId(caseId) {
    return get("/api/cases/"+caseId+"/tags");
  },
  updateCaseTags(caseId, data) {
    return post("/api/cases/"+caseId+"/tags", data);
  },
  removeCaseTags(caseId,data){
    return post("/api/cases/"+caseId+"/tags/remove", data);
  },

  // census
  uploadCensusFile(caseId, file) {
    var formData = new FormData();
    formData.append('upload_csv', file);

    return postFormData("/api/cases/"+caseId+"/census", formData);
  },
  deleteCensus(caseId) {
    return remove(`/api/cases/${caseId}/census`);
  },

  // Tag endpoints
  createTag(data) {
    return post("/api/tags", data);
  },
  updateTag(tagId, data) {
    return put("/api/tags/"+tagId, data);
  },
  deleteTag(tagId) {
    return remove("/api/tags/"+tagId);
  },
  searchTags() {
    return get("/api/tags");
  },
  addTagToGroupOfUsers(tagId, data) {
    return post("/api/tags/"+tagId+"/users", data);
  },

  // Products endpoints
  searchProducts() {
    return get("/api/products");
  },
  getOptionFieldsForProduct(productId) {
    return get(`/api/products/${productId}/options`);
  },

  // Agent Splits endpoint
  updateEnrollmentAgentSplits(enrollmentId, agentSplits) {
    return put(`/api/enrollments/${enrollmentId}/agent_splits`, {'agent_splits': agentSplits})
  },

  // Ext API info endpoints
  getAuthTokenInfo(userId) {
    return get(`/api/users/${userId}/auth-token`)
  },
  resetAuthToken(userId) {
    return post(`/api/users/${userId}/auth-token`, {'generate_new_token': true})
  },
  renewAuthToken(userId) {
    return post(`/api/users/${userId}/auth-token`, {'generate_new_token': false})
  },

  /**
   *
   * @param params
   * @return {Promise<*>}
   */
  postNewEnrollmentSessionConference(params) {
    return post('/api/enrollment-session-conferences', params);
  },


  /**
   *
   * @param {String} conferenceName
   * @return {Promise<*>}
   */
  getConferenceStatus(conferenceName) {
    return get(`/api/enrollment-session-conferences/${conferenceName}/status`);
  },

  /**
   *
   * @return {Promise<*>}
   */
  getCurrentConferenceStatus() {
    return this.getConferenceStatus('current');
  },

  /**
   *
   * @param {String} conferenceName
   * @param {String} toSay
   * @return {Promise<*>}
   */
  sayOnConference(conferenceName, toSay) {
    return post(`/api/enrollment-session-conferences/${conferenceName}/say`, {
      to_say: toSay,
    });
  },

  searchAutoReadScripts() {
    return get(`/api/enrollment-session-conferences/scripts`, {}, {suppressUnauthenticatedMessage: true});
  },

  /**
   *
   * @param {String} conferenceName
   * @param {String} scriptId
   * @return {Promise<*>}
   */
  sayScriptOnConference(conferenceName, scriptId) {
    return post(`/api/enrollment-session-conferences/${conferenceName}/say/scripts`, {
      script_id: scriptId,
    });
  },

  /**
   * @param {String} toSay
   * @return {Promise<*>}
   */
  sayOnCurrentConference(toSay) {
    return this.sayOnConference('current', toSay);
  },

  /**
   * @param {String} scriptId
   * @return {Promise<*>}
   */
  sayScriptOnCurrentConference(scriptId) {
    return this.sayScriptOnConference('current', scriptId);
  },

  cancelConference(conferenceName) {
    return post(`/api/enrollment-session-conferences/${conferenceName}/cancel`, {});
  },
  cancelCurrentConference() {
    return this.cancelConference('current')
  },

  savePaymentSchedule(scheduleData) {
    return post(`/api/wizard/collect-payment-schedule`, scheduleData)
  },
  makePayment(paymentData) {
    return post(`/api/wizard/make-payment-with-app`, paymentData);
  },

  /**
   *
   * @return {Promise<*>}
   */
  getCurrentUnderwritingDecisions() {  // @TODO unused? - EF 2022-06-20
    return get(`/api/enrollment-underwriting-decisions/current`);
  },

  getCurrentUnderwritingDecisionByProductParty(productId, partyId) {
    return get(`/api/enrollment-underwriting-decisions/${productId}/${partyId}`);
  },

  getCurrentUnderwritingDecisionDetailsByProductParty(productId, partyId) {
    return get(`/api/enrollment-underwriting-decisions/${productId}/${partyId}/details`);
  },

  getDeliveries() {
    return get(`/api/deliveries`)
  },
  requestDelivery(enrollmentId) {
    return post(`/api/deliveries/${enrollmentId}`, {})
  },
  downloadDeliveryPackage(enrollmentId) {
    return getDownloadBlob(`/api/deliveries/${enrollmentId}`);
  },

  getRemoteSignature(tokenUUID, caseSlug=null) {
    return get(`/pages/sign/${caseSlug}/${tokenUUID}`);
  },

  validateSelfEnrollToken(tokenUUID, caseSlug=null) {
    return get(`/pages/enroll/${caseSlug}/${tokenUUID}`);
  },

}

function encodeQueryData(data) {
   let ret = [];
   for (let d in data)
     ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
   return ret.join('&');
}
